/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  Tooltip,
} from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { includeDDI } from '_/views/new-service/utils/includeDDI'
import { Loader } from '@refera/ui-web'

import { getClassification } from '_/modules/classification/actions'
import { classificationsSelector } from '_modules/classification/selectors'

import Loading from '_/components/loading'
import { camelize } from '_utils/token'
import { addAgencyStaffUser, getAgency, patchAgencyStaffUser } from '_/modules/agency/actions'
import { agencySelector } from '_modules/agency/selectors'

import { AddCity } from './add-city'
import { BasicInput, MaskBasicInput } from '_/components/inputs'
import useStyles from './styles'
import NavbarButtons from './navbar-buttons'

import { AddGroupPermissions } from './add-group-permission'
import { getProfilePermissionGroup } from '_/modules/profile/actions'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import { EMAIL_REGEX, PHONE_REGEX } from '_/utils/constants'
import { Checkbox } from '@mui/material'
import HeaderTitle from '_/components/header-title'
import { getAgencyStaffUsers } from '_/services/agency'

const AgencyUser = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { agencyId, userId } = useParams()
  const { showToast } = useToast()

  const agency = useSelector(agencySelector)
  const classifications = useSelector(classificationsSelector)

  const groupsPermission = useSelector(getPermissionsGroupSelector)
  const [user, setUser] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [requestLoading, setRequestLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(!userId)
  const [permissionsGroup, setPermissionsGroup] = useState([])

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      cities: user?.cities || [],
    },
    shouldUnregister: false,
  })

  const { handleSubmit, control, setValue, watch } = methods
  const isAdminObserver = watch('isAdmin')

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const handleNavigate = () => {
    navigate(`/imobiliaria/${agencyId}`)
  }

  const handleSave = useCallback(
    async dataObject => {
      // eslint-disable-next-line no-unused-vars
      const { addGroupPermission, ...data } = dataObject

      setRequestLoading(true)
      if (user?.email === data.email) {
        delete data.email
      }

      try {
        const payload = {
          permissionsGroup: permissionsGroup.map(permission => permission.id),
          ...data,
        }

        if (!userId) {
          await dispatch(addAgencyStaffUser(payload))
            .then(() => {
              showToast({
                message: 'Usuário salvo com sucesso.',
                type: 'success',
              })
              handleNavigate()
            })
            .catch(err => {
              if (err.error_message) {
                return showToast({
                  message: err.error_message,
                  type: 'error',
                })
              }

              if (err.response) {
                return showToast({
                  message: err.response.data,
                  type: 'error',
                })
              }

              if (err.detail) {
                return showToast({
                  message: err.detail,
                  type: 'error',
                })
              }

              return showToast({
                message: 'Ocorreu um erro ao salvar o usuário.',
                type: 'error',
              })
            })
            .finally(() => {
              setRequestLoading(false)
            })
        } else {
          await dispatch(patchAgencyStaffUser(userId, payload))
            .then(() => {
              showToast({
                message: 'Usuário salvo com sucesso.',
                type: 'success',
              })
              handleNavigate()
            })
            .catch(err => {
              if (err.error_message) {
                return showToast({
                  message: err.error_message,
                  type: 'error',
                })
              }

              if (err.response) {
                return showToast({
                  message: err.response.data,
                  type: 'error',
                })
              }

              if (err.detail) {
                return showToast({
                  message: err.detail,
                  type: 'error',
                })
              }

              return showToast({
                message: 'Ocorreu um erro ao salvar o usuário.',
                type: 'error',
              })
            })
            .finally(() => {
              setRequestLoading(false)
            })
        }
      } catch (err) {
        if (err.response) {
          showToast({
            message: err.response.data,
            type: 'error',
          })
          setRequestLoading(false)
        }
      }

      return setRequestLoading(false)
    },
    [permissionsGroup, showToast, dispatch]
  )

  useEffect(() => {
    getAgencyStaffUsers(agencyId, userId || undefined)
      .then(data => setUser(camelize(data)))
      .then(() => setPageLoading(false))
  }, [agencyId, userId]) // FETCHES USER

  useEffect(() => {
    if (agencyId) {
      dispatch(getAgency(agencyId))
    }
  }, [agencyId]) // FETCHES AGENCY

  useEffect(() => {
    if (user?.permissionsGroup) {
      setPermissionsGroup(user.permissionsGroup)
    }
    if (user?.getRoles) {
      setValue('role', user.getRoles)
    }
  }, [user])

  useEffect(() => {
    dispatch(
      getClassification({
        pageSize: 1000,
        active: 'True',
        agency: agencyId,
        classificationType: 'auto_responsible_selection',
      })
    )
  }, [])

  const getPermissionsGroup = useCallback(() => {
    if (!groupsPermission?.results || groupsPermission?.results?.length === 0) {
      setPageLoading(true)

      return dispatch(
        getProfilePermissionGroup({
          pageSize: 1000,
          agency: agencyId,
        })
      )
        .then(() => {
          return setPageLoading(false)
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Ocorreu um erro ao buscar os registros dos grupos de permissões.',
          })
          return setPageLoading(false)
        })
    }

    return null
  }, [dispatch])

  // On page first load
  useEffect(() => {
    getPermissionsGroup()
  }, [])

  if ((pageLoading && userId) || (!user?.id && userId)) {
    return <Loading />
  }

  return (
    <>
      <Grid className={styles.main}>
        <FormProvider {...methods}>
          <HeaderTitle
            title={`Cadastro intermediária / ${agency?.toJS().name}`}
            backButtonAction={handleNavigate}
          >
            <NavbarButtons
              isEditing={isEditing}
              isLoading={requestLoading}
              setIsEditing={setIsEditing}
              user={user}
            />
          </HeaderTitle>
          <form id="user-form" onSubmit={handleSubmit(handleSave)} className={styles.container}>
            <Grid className={styles.fieldsContainer}>
              <BasicInput
                label="Nome completo"
                name="name"
                labelClasses={styles.radioLabel}
                required
                disabled={!isEditing}
                defaultValue={user?.name || ''}
              />
              <BasicInput
                label="E-mail"
                name="email"
                required
                labelClasses={styles.radioLabel}
                disabled={!isEditing}
                defaultValue={user?.email || ''}
                rules={{
                  pattern: { value: EMAIL_REGEX, message: 'E-mail inválido' },
                  required: { value: true, message: 'Esse campo é obrigatório' },
                }}
              />
              <MaskBasicInput
                label="Telefone"
                name="phone"
                mask="+55 (99) 9 9999-9999"
                required
                labelClasses={styles.radioLabel}
                disabled={!isEditing}
                rules={{
                  required: { value: true, message: 'Esse campo é obrigatório' },
                  pattern: { value: PHONE_REGEX, message: 'Telefone inválido' },
                }}
                defaultValue={user?.phone ? includeDDI(user?.phone) : ''}
              />
            </Grid>
            <Grid className={styles.fieldsContainer}>
              <Controller
                name="classification"
                control={control}
                defaultValue={user?.classification || []}
                as={
                  <FormControl style={{ width: '100%' }}>
                    <FormLabel shrink id="classification" className={styles.radioLabel}>
                      <Typography variant="body1">Classificação</Typography>
                    </FormLabel>
                    <Select
                      multiple
                      disabled={!isEditing}
                      labelId="classification"
                      id="multiple-classifications"
                      aria-labelledby="classification"
                      onChange={e => setValue('classification', e.target.value)}
                      defaultValue={user?.classification || []}
                      input={<Input label="Classificação" />}
                      MenuProps={menuProps}
                      className={styles.select}
                    >
                      {classifications.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.classificationName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
              {requestLoading && <Loader hasBackdrop open={requestLoading} label="Aguarde..." />}
              <BasicInput
                label="Código externo"
                name="externalCode"
                containerClasses={styles.codeExternField}
                labelClasses={styles.radioLabel}
                disabled={!isEditing}
                defaultValue={user?.externalCode || ''}
              />
            </Grid>
            <Grid className={styles.fieldsContainerFlex}>
              <Controller
                name="isAdmin"
                control={control}
                defaultValue={user?.isAdmin}
                disabled={!isEditing}
                render={({ value, onChange }) => {
                  return (
                    <FormControlLabel
                      label="Admin"
                      control={
                        <Checkbox
                          className={styles.checkbox}
                          checked={value}
                          disabled={!isEditing}
                          defaultChecked={user?.isAdmin}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )
                }}
              />
              <>
                <Controller
                  name="participateInDistribution"
                  control={control}
                  defaultValue={user?.participateInDistribution}
                  disabled={!isEditing}
                  render={({ value, onChange }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={styles.checkbox}
                            checked={value}
                            disabled={!isEditing}
                            defaultChecked={user?.participateInDistribution}
                            onChange={e => onChange(e.target.checked)}
                          />
                        }
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Participa da distribuição</span>
                            <Tooltip
                              arrow
                              disableFocusListener
                              disableTouchListener
                              title={
                                <Typography className={styles.tooltipText}>
                                  Se marcar que o usuário participa da distribuição, o usuário fica
                                  disponível no processo automático de distribuição de chamados e
                                  pode ficar como responsável por chamados, assim que são criados.
                                  Desde que também esteja Ativo.
                                </Typography>
                              }
                              placement="bottom-end"
                            >
                              <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
                            </Tooltip>
                          </div>
                        }
                      />
                    )
                  }}
                />
              </>

              <Controller
                name="isActive"
                control={control}
                defaultValue={user?.isActive || true}
                disabled={!isEditing}
                render={({ value, onChange }) => {
                  return (
                    <FormControlLabel
                      label="Ativo"
                      control={
                        <Checkbox
                          className={styles.checkbox}
                          checked={value}
                          disabled={!isEditing}
                          defaultChecked={user?.isActive}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )
                }}
              />
            </Grid>
            {!isAdminObserver && (
              <AddGroupPermissions
                permissionsGroup={permissionsGroup}
                setPermissionsGroup={setPermissionsGroup}
                isEditing={isEditing}
              />
            )}
            <AddCity isProvider user={user} staffUsers={user?.cities} isEditing={isEditing} />
          </form>
        </FormProvider>
      </Grid>
    </>
  )
}

export default AgencyUser
