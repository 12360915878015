import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: spacing(75),
    width: '100%',
    paddingLeft: spacing(71),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  headerButtons: {
    display: 'flex',
    gap: '8px',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '32px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
    gap: '3rem',
  },
  row: {
    display: 'flex',
    gap: '2rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.5rem',
    marginTop: '10px',
  },
  switchInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-13px',
    gap: '1px',
  },
  switchLabel: {
    color: palette.gray[64],
    marginBottom: spacing(6),
    fontSize: spacing(12),
    width: 'max-content',
    display: 'block',
    marginTop: spacing(10),
  },
  dateTimePicker: {
    '& label': {
      color: `${palette.gray[64]} !important`,
      fontFamily: 'Roboto !important',
      fontWeight: 'normal !important',
    },

    '& .MuiInput-underline': {
      marginTop: '12px !important',
    },

    '& input:disabled': {
      cursor: 'not-allowed !important',
    },
  },
  datePickerInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& input::placeholder': {
      color: 'transparent !important',
    },

    '& input:disabled': {
      cursor: 'not-allowed !important',
    },

    '& input:disabled::placeholder': {
      color: 'transparent !important',
      opacity: 0,
    },
  },
  datePickerLabel: {
    color: palette.gray[64],
    fontFamily: 'Roboto',
    fontSize: '16px !important',
  },
  labelNumberInput: {
    marginTop: '-37px !important',
  },
  tooltipIcon: {
    fontSize: '2rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
    fill: palette.gray[64],
    margin: 0,
  },
  tooltipText: {
    fontSize: '1.4rem',
    color: 'white',
    textAlign: 'right',
  },
  switchField: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.6rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchRentLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordion: {
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
}))
